import React from 'react';
import { ApolloProvider,ApolloClient,HttpLink,InMemoryCache } from '@apollo/client';
import fetch from 'node-fetch';
import {graphql_endpoint} from '../../constants/apis'
const client=new ApolloClient({
    cache: new InMemoryCache(),
        link:new HttpLink({
           uri: graphql_endpoint,
           fetch:fetch
        })
});
const ApolloClientLayout=({children})=>{
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    );
}
export default ApolloClientLayout