import React from 'react';
import { Skeleton } from '@material-ui/lab';
const PageloaderSkeleton = () => {
    return (
        <>
            <div>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" style={{ width: '50%' }} />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" height={200} />
                <Skeleton animation="wave" />
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', marginRight: '5px' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </div>
                <div style={{ width: '50%', marginLeft: '5px' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </div>
            </div>
            <Skeleton animation="wave" />
        </>
    );
}
export default PageloaderSkeleton;