import { Component, lazy } from "react";
const Home = lazy(() => import("./pages"));
const ManagepatientComponent = lazy(() =>
  import("./Component/ManagepatientComponent")
);
const TodaysTestScan = lazy(() => import("./pages/test-scan/today-test-scan"));
const AppointmentHistory = lazy(() =>
  import("./pages/appointments/appointment-history")
);
const Requests = lazy(() => import("./pages/requests/requests"));
const Feedback = lazy(() => import("./pages/feedback/feedback"));
const BookingDoctorwise = lazy(() => import("./pages/booking-doctorWise"));
const TestScanBookingHistory = lazy(() =>
  import("./pages/test-scan/booking-history")
);
const Support = lazy(() => import("./pages/support"));
const BlockContacts = lazy(() => import("./pages/block-contact"));
const OTPSearch = lazy(() => import("./pages/otp"));
const OnlineConsulting = lazy(() => import("./pages/online-consulting"));
const Visitors = lazy(() => import("./pages/clinic-visitors/visitors"));
const PaymentSummary =lazy(()=>import('./pages/payment-summary'));
const BookingSummary =lazy(()=>import('./pages/booking-summary'));
const MonthlyVisiterReport = lazy(()=>import('./pages/clinic-visitors/monthly-report'));
const Doctors=lazy(()=>import('./pages/doctors'));
const Doctorprofile=lazy(()=>import('./pages/doctors/profile'));
const routes = [
  { path: "/today-patients", exact: true, component: ManagepatientComponent },
  {
    path: "/today-to-be-test-test&scan",
    exact: true,
    component: TodaysTestScan
  },
  { path: "/appointment-history", exact: true, component: AppointmentHistory },
  { path: "/requests", exact: true, component: Requests },
  { path: "/feedback", exact: true, component: Feedback },
  {
    path: "/test&scan-booking-history",
    exact: true,
    component: TestScanBookingHistory
  },
  { path: "/support", exact: true, component: Support },
  { path: "/booking-doctorWise", exact: true, component: BookingDoctorwise },
  {
    path: "/block-contacts-for-booking",
    exact: true,
    component: BlockContacts
  },
  { path: "/search-otp", exact: true, component: OTPSearch },
  { path: "/online-consulting", exact: true, component: OnlineConsulting },
  { path: "/clinic-visitors", exact: true, component: Visitors },
  { path: "/payment-summary", exact: true, component: PaymentSummary },
  { path: "/booking-summary", exact: true, component: BookingSummary },
  { path: "/monthly-clinic-visiter-report/:id", exact:true, component:MonthlyVisiterReport},
  { path: "/doctors", exact:true, component:Doctors },
  { path: "/doctor-profile", exact:true, component:Doctorprofile },
  { path: "/", exact: true, component: Home }
];
export default routes;
