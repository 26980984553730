import { SET_WALLET_BALANCE, SET_MEMBERSHIP_TYPE,SET_SRC } from "../constants/account";
import {src_key} from '../constants/storage-keys';

const initialState = {
  wallet_balance: 0,
  is_prime_member: false,
  src:localStorage.getItem(src_key)?localStorage.getItem(src_key):'web',//web,android,ios
};
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLET_BALANCE:
      state = { ...state, wallet_balance: action.payload };
      break;
    case SET_MEMBERSHIP_TYPE:
      state = { ...state, is_prime_member: action.payload };
      break;
    case SET_SRC:
      state={...state,src:action.payload};
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default accountReducer;
