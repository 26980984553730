const get_env_configs = () => {
  if (window.location.hostname === "localhost") {
    return {
      //graphql_endpoint: "https://careipro.com:9000/clinicmanagementgraphql"
       graphql_endpoint: "http://localhost:9010/clinicmanagementgraphql"
    };
  } else {
    return {
      graphql_endpoint: "https://careipro.com:9000/clinicmanagementgraphql"
    };
  }
};
export const { graphql_endpoint } = get_env_configs();
