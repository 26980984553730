const Initialstate={
    onsearching:false,
    closingsearch:false,
    searchingtext:"",
    selecteddate:'',
    showpatientlistdatewise:false,
    apply_filter:false,
    filter_data:{},
    clear_filter:false
};
export const patientlistreducer=(state=Initialstate,action)=>{
  switch(action.type){
    case 'GET_PATIENT_LIST_DATEWISE':
    state={...state,showpatientlistdatewise:true,selecteddate:action.payload};
    break;
    case 'ON_SEARCHING':
    state={...state,onsearching:true,searchingtext:action.payload,showpatientlistdatewise:false};
    break;
    case 'ON_CANCEL_SEARCHING':
    state={...state,closingsearch:true,onsearching:false};
    break;
    case 'APPLY_FILTER':
    state={...state,apply_filter:true,filter_data:action.payload,showpatientlistdatewise:false};
    break;
    case 'CLEAR_FILTER':
    state={...state,clear_filter:true,onsearching:false,closingsearch:false,searchingtext:'',selecteddate:'',showpatientlistdatewise:false,apply_filter:false,filter_data:{}};
    break;
    default:
    state={...state};
  }
  return state
}
export default patientlistreducer;