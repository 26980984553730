import { combineReducers } from "redux";
import { patientlistreducer } from "./patientlistreducer";
import authorization from "./authorization";
import onlineConsulting from "./online-consulting";
import accountReducer from "./account";
export const mainReducer = combineReducers({
  patientlistreducer,
  authorization,
  onlineConsulting,
  accountReducer
});
export default mainReducer;
