import React, { Component,Suspense } from 'react';
import { Provider } from 'react-redux'
import 'font-awesome/css/font-awesome.min.css';
import store from './store';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from './routes';
import PageloaderSkeleton from './Component/template/page-loader-skeleton';
import ApolloClientLayout from './Component/layouts/apollo-provider';
import './css/main.css';
import './css/icons.css';
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ApolloClientLayout>
        <Suspense fallback={<PageloaderSkeleton/>}>
        <Router>
          <Switch>
            {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  render={props => (
                    <route.component {...props} />
                  )} />
              ) : (null);
            })}
          </Switch>
        </Router>
        </Suspense>
        </ApolloClientLayout>
      </Provider>
    )
  }
}

export default App;
