import { MAKE_CALL, DISCONNECT_CALL, PATIENT_ANSWERED, NEW_ONLINE_USER,USER_WENT_OFFLINE,
    SET_BOOKING_STATUS,CALL_COMPLETED } from '../constants/online-consulting';
const initialState = {
    calling: false,
    incall: false,
    appointment_id: null,
    doctor_id:null,
    status:'',
    new_online_user: 0,
    offline_user: 0,
}
const onlineConsulting = (state = initialState, action) => {
    switch (action.type) {
        case MAKE_CALL:
            state = { ...state, calling: true, appointment_id: action.payload.appointment_id,doctor_id:action.payload.doctor_id };
            break;
        case DISCONNECT_CALL:
            state = { ...state, calling: false, incall: false, appointment_id: null };
            break;
        case PATIENT_ANSWERED:
            state = { ...state, calling: false, incall: true }
            break;
        case NEW_ONLINE_USER:
            state = { ...state, new_online_user: action.payload }
            break;
        case USER_WENT_OFFLINE:
            state = { ...state, offline_user: action.payload }
            break;
        case SET_BOOKING_STATUS:
            state = { ...state, status: action.payload }
            break;
        case CALL_COMPLETED:
            state={...state,status:'',appointment_id:null,doctor_id:null}
            break;
        default:
            state = { ...state };
    }
    return state;
}
export default onlineConsulting;